import api from "../../../services/api";
import axios from "axios";
export default {
  name: "AdesaoCad",
  props: ["emp"],

  data() {
    return {
      adesao: [],
      titulo: "Nova Adesão",

      logo: "salus.png",

      ativProf: [],

      form: {
        nome: "",
        cpf: "",
        email: "",
        confEmail: "",
        celular: "",
        celAdic: "",
        fixo: "",
        cep: "",
        cidade: "",
        uf: "",
        bairro: "",
        endereco: "",
        atividadeProf: null,
        regProf: "",
        regNum: "",
        regDataVencimento: null
      },

      // form: {
      //   nome: " marcos rober",
      //   cpf: "329.207.778-82",
      //   email: "kepas7@gmail.com",
      //   confEmail: "kepas7@gmail.com",
      //   celular: "11111",
      //   celAdic: "",
      //   fixo: "",
      //   cep: "03570-370",
      //   cidade: "São Paulo",
      //   uf: "as",
      //   bairro: "asas",
      //   endereco: "asas",
      //   atividadeProf: 1,
      //   regProf: "1212",
      //   regNum: "12",
      //   regDataVencimento: null
      // },
     
      show: true,
      botaoVisivel: true,

      dadosEmpresa: null,
      bgColor: "#a1a1a1",

    };
  },

  created() {

    // alert(this.$route.query.emp)
    const empresa = this.$route.query.emp

    api
      .get(`adesao/empresa/${empresa}/null`)
      .then((res) => {
        // console.log(res.data);
        this.dadosEmpresa = res.data;
        this.bgColor = res.data.cor;
        this.logo = res.data.logoPath;
        this.ativProf = res.data.funcoes;

        if (!this.dadosEmpresa) this.$router.push("/adesao/erro");
      })
      .catch(() => {
        if (!this.dadosEmpresa) this.$router.push("/adesao/erro");
        //this.msg("ERRO AO BUSCAR", err.response.data, "danger")
      });

  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 10000,
        appendToast: true
      });
    },


    insertAdesao(evt) {
      evt.preventDefault();

      this.botaoVisivel = false

      this.form.idEmpresa = this.dadosEmpresa.id
      this.form.nomeEmpresa = this.dadosEmpresa.nomeEmpresa
      this.form.diasReprovado = this.dadosEmpresa.diasReprovado
      this.form.codUrl = this.dadosEmpresa.codUrl
      
      api
        .post("adesao", this.form)
        .then((res) => {

          //console.log(res.data);
          if (res.data == 'ok') {
            this.msg("ADESÃO", "CADASTRADO COM SUCESSO.", "success");
          } else 
          if (res.data == 'ok?') {
            this.$refs['my-modal'].show()
            return
          } 

          this.form = '';
          localStorage.setItem('idMsg', 10)
          localStorage.setItem('dadosEmpresa', JSON.stringify(this.dadosEmpresa))
          this.$router.push('/msg');

        })
        .catch((err) => {
          this.msg("ATENÇÃO!", err.response.data, "danger")
          this.botaoVisivel = true
        });

    },


    buscarCep() {
      // console.log("cep", this.form.cep);

      this.form.endereco = null
      this.form.bairro = null
      this.form.cidade = null
      this.form.uf = null

      if (this.form.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.form.cep}/json`)
          .then((res) => {
            console.log(res.data);

            const dadosCep = res.data;
            this.form.endereco = dadosCep.logradouro;
            this.form.bairro = dadosCep.bairro;
            this.form.cidade = dadosCep.localidade;
            this.form.uf = dadosCep.uf;
          })
          .catch((err) => this.msg("Erro: cep não encontrado.", err, "danger"));
      }

    },

    getEntidade() {
      
      api
      .get('adesao/empresa-entidade/' + this.form.atividadeProf)
      .then((res) => {
        
        this.form.regProf = res.data
      })
      .catch(() => this.msg("ATENÇÃO", 'Erro ao buscar entidade', 'danger'));

    },


    hideModal() {
      this.$refs['my-modal'].hide()
    },
    confirmaAdesao(acao) {
      
      this.$refs['my-modal'].hide()

      if (acao == 'sim') {

        api.put(`adesao/confirma-ok/${this.dadosEmpresa.id}/${this.form.cpf}`)
        .then(() => {
          this.form = '';
          localStorage.setItem('idMsg', 10)
          localStorage.setItem('dadosEmpresa', JSON.stringify(this.dadosEmpresa))
          this.$router.push('/msg');
        })
        .catch(() => this.msg("ATENÇÃO", 'Erro...', 'danger'));

      } else {
        
        api.delete(`adesao/${this.dadosEmpresa.id}/${this.form.cpf}`)
        .then(() => {
          this.msg('ADESÃO', 'CADASTRADO NÃO CONCLUÍDO.', 'warning');
        })
        .catch(() => this.msg("ATENÇÃO", 'Erro...', 'danger'));

      }

    },


  },
  

  computed: {
    cssVars() {
      return {
        "--bg-color": this.bgColor,
        "--height": this.height + "px",
      };
    },
  },
};